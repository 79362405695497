<template>
  <div>
    <div class="title">学习目录</div>
    <!-- <recordtime type="3" record="1"> </recordtime>
    <showtime type="3"></showtime> -->
    <writetime type="3"></writetime>
    <el-collapse style="margin-bottom: 500px">
      <el-collapse-item
        :title="item.title"
        v-for="(item, inx) in list"
        :key="inx"
      >
        <div class="item" v-for="(item1, index) in item.unit" :key="index">
          <div class="item_1">{{ item1.title }}</div>
          <div class="item_2">
            <div v-if="item1.vstatus == 1" class="item_2_1">已学习</div>
            <div v-else class="item_2_11">未学习</div>
            <div class="item_2_2" @click="go(inx, index, item1, item)">
              去学习
            </div>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      list: [],
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
      classid: (state) => state.classid,
    }),
  },
  methods: {
    ...mapActions(["getClassCharter"]),
    go(inx, index, item, data) {
      // this.$router.push("/Layoutstudy/knowdetail");
      this.$store.commit("SET_STUDYDES", {
        chapterlist: data.unit,
        index: index,
      });
      this.$router.push({
        path: "/Layoutstudy/knowdetail",
        query: item,
      });
    },
  },
  mounted() {
    this.getClassCharter({
      username: this.userInfo.username,
      classid: this.classid,
    }).then((res) => {
      if (res.code == 200) {
        this.list = res.data.chapter;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  height: 58px;
  line-height: 20px;
  border-bottom: 1px solid #e1e1e1;
}
.item {
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  margin-bottom: 23px;
  .item_1 {
    margin-left: 68px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #646464;
    &::before {
      content: "";
      transform: translateY(-3px);
      display: inline-block;
      width: 6px;
      height: 6px;
      background: #0038ff;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  .item_2 {
    display: flex;
    .item_2_1 {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ff6a00;
      margin-right: 59px;
    }
    .item_2_11 {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #137cfb;
      margin-right: 59px;
    }
    .item_2_2 {
      cursor: pointer;
      text-align: center;
      line-height: 30px;
      width: 100px;
      height: 30px;
      background: #e9f3ff;
      border: 2px solid #137cfb;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #137cfb;
    }
  }
}
</style>
